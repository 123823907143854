import { debounce, isEqual } from 'lodash-es';
import { useEffect } from 'react';

export function useKeyDownKeyboardListener(onKeyDown: (e: any) => void) {
	useEffect(() => {
		window.addEventListener('keydown', onKeyDown);

		return () => {
			window.removeEventListener('keydown', onKeyDown);
		};
	}, [onKeyDown]);
}

let recordingKey = false;
let keyStack: string[] = [];

export const keyListener = debounce((event: () => void) => {
	event?.();

	recordingKey = false;
	keyStack = [];
}, 1000);

export const keyRecorder = (e: KeyboardEvent) => {
	if (recordingKey) {
		keyStack.push(e.key);
	}
};

export const startRecodeKey = (
	group: {
		touchKey: string[];
		endCallback: () => void;
	}[],
) => {
	if (!recordingKey) {
		recordingKey = true;

		keyListener(() => {
			group.forEach((item) => {
				const { touchKey, endCallback } = item;
				console.log(keyStack);
				if (isEqual(keyStack, touchKey)) {
					endCallback?.();
				}
			});
		});
	}
};

export function shortCutSave(e: KeyboardEvent, cb: any) {
	if (e.metaKey && e.key === 's') {
		e.preventDefault();
		cb?.();
	}

	if (e.key === 's' && (e.ctrlKey === true || e.altKey === true)) {
		e.preventDefault();
		cb?.();
	}
}
